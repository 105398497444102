.home-container {
    /* padding-top: 170px; */
    position: relative;

    /* border: 1px solid red; */
}

/* ------ HERO ------- */
.home-container .hero {
    margin: 0 200px;
    padding-top: 170px;
    height: 100vh;
    /* border: 1px solid red; */
    position: relative;
}

.home-container .hero i {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%) scaleX(1.5);
    color: rgb(163, 164, 255);
    text-shadow: 0 0 200px #ffffff;

    font-size: 30px;
    animation: jump 1.2s ease-in-out infinite;
}

@keyframes jump {
    0%, 100% {
        transform: translateX(-50%) translateY(0) scaleX(1.5); /* Default position */
    }
    50% {
        transform: translateX(-50%) translateY(-10px) scaleX(1.5); /* Move up by 10px */
    }
}

.home-container .hero #welcome,
.home-container .hero #paragraph {
    font-weight: bold;
    font-size: 40px;
    background: linear-gradient(to right, rgb(95, 97, 255), rgb(187, 188, 255), rgb(95, 97, 255), rgb(187, 188, 255), rgb(95, 97, 255));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 200% 100%;
    animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0% 0;
    }
}

.home-container .hero #welcome {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}

.home-container .hero #paragraph {
    display: flex;
    font-weight: bold;
    font-size: 45px;
    line-height: 1.2;
}

/* ------ PROJECTS ------- */
.home-container .projects-container {
    margin: 0 200px;
    position: relative;
    /* border: 1px solid red; */
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid rgba(127, 129, 255, 0.375); */
}

.home-container .projects-container .all-projects {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.projects-container .all-projects .outer-shroud {
    border-radius: 20px;
    padding: 10px;
    background-color: rgb(40, 40, 40);

    transition: transform 0.3s ease-in-out;
}

.projects-container .all-projects .shroud {
    border: 1px solid rgba(186, 186, 186, 0.375);
    border-radius: 14px;
    padding: 110px 100px 0px;
    background-color: rgb(23, 23, 23);
    position: relative;
    overflow: hidden;

    transition: 0.3s ease;
}

.projects-container .all-projects .outer-shroud:hover .shroud {
    border: 1px solid rgba(180, 180, 180, 0.565)
}


.projects-container .all-projects #riddym .shroud::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 255, 170, 0.335), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 1s ease;
    z-index: 0;
}

.projects-container .all-projects #riddym:hover .shroud::before {
    opacity: 1;
}

.projects-container .all-projects #babbl .shroud::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(125, 78, 255, 0.335), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 1s ease;
    z-index: 0;
}

.projects-container .all-projects #babbl:hover .shroud::before {
    opacity: 1;
}

.projects-container .all-projects #fortbnb .shroud::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(200, 161, 255, 0.379), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 1s ease;
    z-index: 0;
}

.projects-container .all-projects #fortbnb:hover .shroud::before {
    opacity: 1;
}

.projects-container .all-projects .shroud .project-title {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 30px;
    left: 40px;

    z-index: 1;
}

.projects-container .all-projects .shroud .arrow {
    font-size: 30px;
    position: absolute;
    top: 50px;
    right: 40px;
    color: rgba(255, 255, 255, 0.61);

    transition: 0.3s ease;
}

.projects-container .all-projects .outer-shroud:hover .shroud .arrow {
    right: 30px;
    color: white;
}

.projects-container .all-projects .shroud .project-title span {
    color: rgb(187, 187, 187);
}

.projects-container .all-projects .shroud .project-title h1 {
    font-size: 25px;
    font-weight: normal;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.61);
}

.projects-container .all-projects .shroud .project-title h3 {
    font-size: 18px;
}

.home-container .projects-container h3 {
    color: gray;
    font-weight: normal;
    margin-bottom: 30px;
}

.home-container .projects-container .project {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    /* border: 1px solid rgba(192, 192, 192, 0.47); */

    transform: translateY(40px);
    transition: transform 0.3s ease-in-out;
}

.projects-container .all-projects .outer-shroud:hover .project {
    transform: translateY(5px);
}

.home-container .projects-container .project .top-bar {
    /* background-color: rgb(64, 64, 64); */
    background-color: rgba(87, 87, 87, 0.767);
    height: 35px;
    width: 100%;
    padding: 5px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* box-shadow: 0px 3px 24px 1px rgba(0,0,0,0.75); */
    backdrop-filter: blur(10px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    /* border: 1px solid red; */
}

.home-container .projects-container .project .top-bar .dot-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    position: absolute;
    left: 20px;
}

.home-container .dot-container .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.home-container .dot-container #dot-one {
    background-color: rgb(253, 103, 103);
}

.home-container .dot-container #dot-two {
    background-color: rgb(255, 214, 90);
}

.home-container .dot-container #dot-three {
    background-color: rgb(100, 219, 100);
}

.home-container .search-bar {
    width: 400px;
    height: 20px;
    /* background-color: rgb(42, 42, 42); */
    background-color: rgba(91, 91, 91, 0.187);
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 7px 7px;
    gap: 5px;

    border-radius: 5px;
    backdrop-filter: blur(10px);
}

.home-container .search-bar span {
    /* color: rgb(145, 145, 145); */
    color: rgb(178, 178, 178);
}

.home-container .search-bar img {
    height: 13px;
}

/* ------ TECH ------- */
.home-container .tech {
    margin: 0 200px;
    position: relative;
    /* border: 1px solid red; */
    padding-bottom: 70px;
}

.home-container .tech .title,
.home-container .projects-container .title {
    margin-top: 10px;
}

.home-container .tech h3 {
    color: gray;
    font-weight: normal;
    margin-bottom: 30px;
}

.home-container .tech .logos-container {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: space-between; */
    justify-content: center;

    gap: 14px;

    width: 100%;

    background-color: rgba(40, 40, 40, 0.279);
    padding: 25px;
    border-radius: 20px;

    /* border: 1px solid red; */
}

.home-container .tech .logos-container img {
    width: 98px;
    padding: 15px;
}

.home-container .tech .logos-container .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 20px;
    /* background-color: rgb(50, 50, 50); */
    transition: transform 0.3s ease-out;
    position: relative;
    display: flex;

    transition: 0.6s ease;
}

.home-container .tech .logos-container .tooltip:hover {
    background-color: rgba(50, 50, 50, 0.697);
    cursor: default;
}

.home-container .tech .logos-container .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgba(149, 149, 149, 0.404);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    backdrop-filter: blur(5px);
}

.home-container .tech .logos-container .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(149, 149, 149, 0.404) transparent transparent transparent;
}


.home-container .tech .logos-container .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.home-container .tech .logos-container .tooltip #vert {
    filter: invert(100%) brightness(100%) contrast(100%);
}

/* ------ FOOTER ------- */
.home-container .footer {
    background-color: rgba(0, 0, 0, 0.191);
    padding: 60px 200px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
}

.home-container #copyright {
    display: flex;
    align-items: center;
    gap: 2px;
}

.home-container .footer i {
    font-size: 14px;
}

.footer .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: 100%;
}

.footer .right {
    display: flex;
    gap: 40px;

    height: 100%;
}

.footer .right .main,
.footer .right .connect {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer .right p {
    font-size: 12px;
    color: rgb(167, 167, 167);
}

.footer .right a {
    color: white;
    text-decoration: none;

    transition: 0.4s ease;
}

.footer .right a:hover {
    color: rgb(167, 168, 255);
}

/* ------ SCROLL ANIMATIONS ------- */
/* .outer-shroud {
    animation: autoShowAnimation both;
    animation-timeline: view(100% 2%);
}

@keyframes autoShowAnimation {
    from {
        opacity: 0;
        transform: translateY(100px) scale(0.3);
        box-shadow: 0 0 1000px rgba(160, 158, 255, 0.944);
    } to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
} */

.outer-shroud {
    opacity: 0;
    /* transform: translateY(100px) scale(0.3);
    transition: opacity 1s, transform 1s;
    animation-fill-mode: forwards; */
}

.outer-shroud.show {
    animation: autoShowAnimation 1s forwards;
}

@keyframes autoShowAnimation {
    from {
        opacity: 0;
        transform: translateY(100px) scale(0.5);
        box-shadow: 0 0 1000px rgba(160, 158, 255, 0.944);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
        box-shadow: none;
    }
}

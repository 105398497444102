.machine-demo-page {
    background-color: white;
    width: 100vw;
    height: 100vh;

    overflow-y: scroll;
    color: black;
}

.machine-demo-page .nav-bar {
    width: 80px;
    height: 100vh;
    background-color: rgb(37, 37, 45);

    padding: 10px 15px;

    position: fixed;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 1000;
}

.machine-demo-page .nav-bar span {
    height: 2px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.312);
    margin: 20px 0 20px;
}

.machine-demo-page .nav-bar img {
    height: 50px;
}

.machine-demo-page .nav-bar .logo-container {
    /* border: 1px solid white; */

    display: flex;
    justify-content: center;
    align-items: center;
}

.machine-demo-page .nav-bar .options {
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;
}

.machine-demo-page .nav-bar .option-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50px;
    height: 50px; */
}

.machine-demo-page .nav-bar .option-item.active i {
    color: white;
    background-color: rgb(239, 81, 57);
}

.machine-demo-page .nav-bar .option-item.active i:hover {
    background-color: rgb(239, 81, 57);
    color: white;
}

.machine-demo-page .nav-bar .options i {
    font-size: 22px;
    width: 48px;
    height: 48px;

    color: rgb(144, 144, 144);
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.3s ease;
}

.machine-demo-page .nav-bar .options i:hover {
    background-color: rgba(156, 156, 156, 0.133);
    cursor: pointer;
}

.machine-demo-page .content {
    height: 100vh;

    margin-left: 80px;

    /* border: 2px solid blue; */
}

/* ----------------------------------- */
/* ------   NAV BAR TOOL TIP   ------- */
/* ----------------------------------- */
.machine-demo-page .nav-bar .option-item .tooltip {
    position: absolute;
    left: 40px;
    background-color: rgb(54, 54, 54);
    color: white;
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    /* transform: translateX(-10%); */
    transition: opacity 0.2s ease, transform 0.2s ease;
    pointer-events: none;
    z-index: 999;
}

.machine-demo-page .nav-bar .option-item:hover .tooltip {
    opacity: 1;
    transform: translateY(0%) translateX(35px);
}

.machine-demo-page .nav-bar .option-item .tooltip::before {
    content: '';
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent rgb(54, 54, 54) transparent transparent;
}

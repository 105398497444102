.nav-container {
    /* border: 1px solid red; */
    position: fixed;
    z-index: 666;

    width: 100vw;

    display: flex;
}

.nav-container .section-one .image-container img {
    width: 90px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.142);
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px;
}


.section-one .logo {
    filter: invert(55%) sepia(52%) saturate(709%) hue-rotate(198deg) brightness(108%) contrast(101%);
}

.nav-container .section-one {
    /* padding: 0 30px; */
    width: 100%;
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.nav-container .section-one .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: 0.4s ease;
    /* padding: 10px; */
    margin: 10px;
    /* border-radius: 50%; */
    cursor: pointer;
    height: 60px;
    width: 60px;

    /* overflow: hidden; */
    position: relative;

    /* border: 1px solid blue; */
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.nav-container .section-one .about::after,
.nav-container .section-one .about::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: conic-gradient(from var(--angle), rgb(73, 76, 255), rgb(95, 97, 255), rgb(187, 188, 255), rgb(218, 218, 255), rgb(73, 76, 255));
    z-index: -550;
    padding: 3px;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 50%;
    animation: 3s spin linear infinite;
}

.nav-container .section-one .about::before {
    filter: blur(0.4rem);
    opacity: 0.5;
}

@keyframes spin {
    from {
        --angle: 0deg;
    }
    to {
        --angle: 360deg;
    }
}

.nav-container .section-one .about img {
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 50%;
    /* transform: scale(1. */
    transition: 0.5s ease;
}

.image-i {
    border-radius: 50%;
    overflow: hidden;
}

.nav-container .section-one .about img:hover {
    transform: scale(2);
}

.nav-container .section-one .about h3 {
    font-size: 25px;
    color: rgb(147, 147, 147);
    cursor: pointer;
}

/* ----- BACK BUTTON ----- */
.about-page {
    box-sizing: border-box;
}

.about-page .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    color: rgba(255, 255, 255, 0.196);

    padding: 10px 20px;
    background-color: rgba(75, 75, 75, 0.087);
    border-radius: 10px;

    transition: 0.3s ease;

    cursor: pointer;
    min-width: fit-content;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 1;
}

.about-page .back-button:hover {
    background-color: rgba(72, 72, 72, 0.597);
    color: rgba(255, 255, 255, 0.619);
}

.about-page .content {
    display: flex;

    /* padding: 0 10px; */
    margin: 30px 140px 0;

    /* border: 1px solid blue; */
}

.about-page .content {

}

/* ----- ROW ONE ----- */
.about-page .row-one {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    /* border: 1px solid red; */
    gap: 10px;
    height: 400px;
    background-color: rgb(34, 34, 34);
    padding: 20px;
    border-radius: 34px;
}

.about-page .row-one .profile.block {
    border: 10px solid rgb(42, 42, 42);

    border-radius: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.about-page .profile.block img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.about-page .row-one .description.block {
    /* background-color: rgb(42, 42, 42); */
    font-size: 32px;

    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-page .row-one .description.block .text-block {
    padding: 30px;
}

.about-page .row-one .description.block .text-block .header {
    font-family: Akira;
    text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        0 0 10px rgba(255, 255, 255, 0.6),
        0 0 100px rgba(255, 255, 255, 0.4);
}

.about-page .row-one .description.block .text-block .body {
    font-family: SF Pro;
}

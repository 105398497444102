.machines-container {
    width: 100%;
    height: 100vh;
    padding: 30px;
    color: black;
}

.machines-container .title h1 {
    margin-bottom: 30px;
}

* {
  font-family: 'SF Pro';
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

html, body {
  background-color: #111111;
  /* background-color: white; */
  color: rgb(255, 255, 255);
}

@font-face {
  font-family: 'SF Pro';
  src: url('../public/font/SFPRODISPLAYREGULAR.OTF') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../public/font/SFPRODISPLAYBOLD.OTF') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Akira';
  src: url('../public/font/Akira-Expanded-Demo.otf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/font/Nexa-ExtraLight.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/font/Nexa-Heavy.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lemontea';
  src: url('../public/font/Lemontea.ttf') format('truetype');
  font-weight: normal;
}

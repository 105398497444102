.machine-jobs-container {
    /* text-align: center; */
    width: 100%;
    height: 100%;
    padding: 30px;
    border: 3px solid red;
    overflow-y: scroll;
}

.machine-jobs-container .title h1 {
    margin-bottom: 30px;
}

.machine-jobs-container .machines-module {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    /* height: 100%; */

    border: 2px solid blue;
}

.machines-module .machine-selector  {
    flex: 1;
    max-width: 50%;

    border: 2px solid green;
}

.machines-module .job-data {
    flex: 1;
    max-width: 50%;
    /* min-height: 300px; */

    border: 2px solid purple;
}

.machine-list {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 10px;
    justify-content: center;
    flex-direction: column;

    border: 2px solid red;
}

.machine-list button {
    padding: 10px 15px;
    border: none;
    background: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
}

.machine-list button:hover {
    background: #0056b3;
}

.machine-list button.selected {
    background: #ffcc00;
    color: black;
}

.loading {
    font-size: 18px;
    font-weight: bold;
}


.job-group {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
    background: #f9f9f9;
}

.job-group h4 {
    margin-bottom: 5px;
}
